body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

select {
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.app {
  text-align: left;
}

.feed {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  padding: 8px 8px 48px;
}

.section {
  margin: 16px 0px;
}

.logo {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin: 24px 0px;
}

.bottom-btns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 754px;
  padding: 8px;
  background-color: #fafafa;
  display: flex;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
}

.bottom-content {
  display: flex;
  max-width: 768px;
  padding: 8px;
  width: 100%;
  margin: 0 auto;
}

a {
  color: inherit;
  text-decoration: inherit;
  border: 0;
  padding: 0;
}

a.stdLink {
  text-decoration: underline;
  color: blue;
}

.red {
  color: #d90303;
}

.green {
  color: #03d907;
}

.MuiTableCell-sizeSmall {
  padding: 2px !important;
}

.hover-container .show-on-hover {
  display: none;
}

.hover-container:hover .show-on-hover {
  display: block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

